import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from 'layout/page/Page.layout.styled';
import Button from 'components/common/button/Button';
import { GenericDiv } from 'styles/helpers/utils';
import { prem } from 'styles/helpers/spacing';
import {
  QuestionnaireContent,
  QuestionnaireFormIntro,
  QuestionnaireSlideWrapper,
} from 'pages/questionnaire/Questionnaire.styled';
import { FormFieldsGroup } from 'components/common/form/Form.styled';
import SelectBox, { SelectBoxProps } from 'components/common/form/selectbox/SelectBox';
import { Form, IActivities } from 'types/app';
import useFormHandler from 'hooks/useFormHandler';
import useAPIQuestionnaire from 'hooks/api/useAPIQuestionnaire';
import { getLocaleValue } from 'helpers/utils';
import { useSwiper } from 'swiper/react';
import CustomSelect from 'components/common/form/custom-select/CustomSelect';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import countriesI18n from 'i18n-iso-countries';
import enJson from 'i18n-iso-countries/langs/en.json';
import frJson from 'i18n-iso-countries/langs/fr.json';
import { TFunction } from 'i18next';

countriesI18n.registerLocale(enJson);
countriesI18n.registerLocale(frJson);

const countryCodes = countriesI18n.getAlpha2Codes();
const countryList = Object.keys(countryCodes).map(code => ({
  en: countriesI18n.getName(code, 'en') || '',
  fr: countriesI18n.getName(code, 'fr') || '',
  code: code
}));

export interface IQuestionnaireProfileForm {
  activities: IActivities;
  currentActivity?: string;
  currentBirthYear?: string;
  currentCivility?: string;
}

const getYearsList = (min: number, max: number): Array<Form.IOption> => {
  const yearsRange = Array.from({ length: max - min + 1 }, (_, index) => max - index);
  return yearsRange.map((year, index) => ({
    id: index,
    label: year.toString(),
    value: year.toString(),
  }));
};

const YEAR_MIN = 1920;
const YEAR_MAX = new Date().getFullYear() - 15;

const getCountriesList = (lang: 'fr' | 'en'): Array<Form.IOption> => {
  return countryList.map(((country, key) => {
    return {
      id: key,
      label: country[lang],
      value: country.code.toLowerCase()
    };
  }));
};

const getCivilitiesList = (translateFunc: TFunction<'translation', undefined>): Array<Form.IOption> => {
  return [
    {
      id: -1,
      label: '',
      value: '',
    },
    {
      id: 0,
      label: translateFunc('common.form.pronoun.him'),
      value: 'Monsieur',
    },
    {
      id: 1,
      label: translateFunc('common.form.pronoun.her'),
      value: 'Madame',
    },
  ];
};

const QuestionnaireProfileForm: React.FC<IQuestionnaireProfileForm> = ({
  activities,
  currentActivity,
}) => {
  const { i18n, t } = useTranslation('translation');
  const { user, updateUser } = useContext(UserContext) as UserContextProps;
  const formEl = useRef<HTMLFormElement | null>(null);
  const parentSwiper = useSwiper();
  const { updateUserInformation } = useAPIQuestionnaire();
  const getValueFromOptions = (options: Array<Form.IOption>, value?: string): Form.IOption | undefined => {
    if (!value) return;
    return options.find((option) => option.value === value);
  };

  const getActivitiesList = (activities: IActivities): Array<Form.IOption> => {
    return activities.map((activity, index) => ({
      id: index,
      label: getLocaleValue(activity.label, i18n.language),
      value: getLocaleValue(activity.code, i18n.language),
    }));
  };

  const {
    fields,
    isFormValid,
    getFieldByName,
    updateFieldValue,
    resetErrors,
  } = useFormHandler([
    {
      name: 'civility',
      required: true,
      options: getCivilitiesList(t),
      value: getValueFromOptions(getCivilitiesList(t), user?.civilityInDatabase),
    },
    {
      name: 'birth_year',
      required: false,
      options: getYearsList(YEAR_MIN, YEAR_MAX),
      value: getValueFromOptions(getYearsList(YEAR_MIN, YEAR_MAX), user?.profileInfo?.birthYear),
    },
    {
      name: 'activity',
      required: false,
      options: getActivitiesList(activities),
      value: getValueFromOptions(getActivitiesList(activities), currentActivity)
    },
    {
      name: 'country',
      required: true,
      options: getCountriesList(i18n.language === 'fr' ? 'fr' : 'en'),
      value:  getValueFromOptions(getCountriesList(i18n.language === 'fr' ? 'fr' : 'en'), user?.profileInfo?.nationality),
      defaultValue: user?.profileInfo?.nationality || '',
    },
  ]);

  const formatFieldsPayload = () => {
    return {
      civility: (getFieldByName('civility', fields).value as Form.IOption)?.value,
      birth_year: (getFieldByName('birth_year', fields).value as Form.IOption)?.value,
      activity: (getFieldByName('activity', fields).value as Form.IOption)?.value,
      country: (getFieldByName('country', fields).value as Form.IOption)?.value,
    };
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    resetErrors();
    const payload = formatFieldsPayload();
    if (isFormValid()) {
      await updateUserInformation(`${parseInt(payload.birth_year as string) > 0  ? payload.birth_year : ''}`, `${payload.activity ?? ''}`, `${payload.country ?? ''}`, `${payload.civility ?? ''}`);
      if (payload.civility) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updateUser({
          ...user,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          civilityInDatabase: payload.civility,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          civility: payload.civility
        });
      }
      setTimeout(() => {
        parentSwiper.slideNext();
      }, 250);
    }
  };

  return (
    <QuestionnaireSlideWrapper>
      <QuestionnaireContent className="content">
        <PageTitle className='title-before-questionnaire-starts'>{t('questionnaire.before-start')}</PageTitle>
        <QuestionnaireFormIntro>{t('questionnaire.before-start-intro')}</QuestionnaireFormIntro>
        <div className="intro-form">
          <form noValidate onSubmit={onSubmit} autoComplete="off" ref={formEl}>
            <FormFieldsGroup>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <CustomSelect
                {...({
                  ...getFieldByName('country', fields),
                  label: t('common.country'),
                  onChange: (value) => updateFieldValue('country', value || ''),
                } as SelectBoxProps)}
              />
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {/* <CustomSelect
                {...({
                  ...getFieldByName('civility', fields),
                  label: t('common.pronoun'),
                  onChange: (value) => updateFieldValue('civility', value?.value || ''),
                } as SelectBoxProps)}
              /> */}
              <SelectBox
                {...({
                  ...getFieldByName('civility', fields),
                  label: t('common.pronoun'),
                  isNative: true,
                  onChange: (value?) => updateFieldValue('civility', value || ''),
                } as SelectBoxProps)}
              />
              <SelectBox
                {...({
                  ...getFieldByName('birth_year', fields),
                  label: t('common.year-of-birth'),
                  isNative: true,
                  onChange: (value?) => updateFieldValue('birth_year', value || ''),
                } as SelectBoxProps)}
              />
              <SelectBox
                {...({
                  ...getFieldByName('activity', fields),
                  label: t('common.activity'),
                  isNative: true,
                  onChange: (value?) => updateFieldValue('activity', value || ''),
                } as SelectBoxProps)}
              />
              <GenericDiv
                className="submit-button"
                minWidth={prem(100)}
                maxWidth={prem(150)}
                center
              >
                <Button type="submit" fill="outline">
                  <span>{t('common.validate')}</span>
                </Button>
              </GenericDiv>
            </FormFieldsGroup>
          </form>
        </div>
      </QuestionnaireContent>
    </QuestionnaireSlideWrapper>
  );
};

export default QuestionnaireProfileForm;
