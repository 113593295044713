import { useContext } from 'react';
import useFetch from 'hooks/api/useFetch';
import { API, PRIMARY_QUESTIONNAIRE_NB, TOTAL_QUESTIONNAIRE } from 'constants/global';
import { IActivities, ISectionBaseTexts, Questionnaire } from 'types/app';
import { AxiosRequestConfig } from 'axios';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { IHttpResponse } from 'helpers/api';
import { useTranslation } from 'react-i18next';
import { getSectionBaseTexts, getTranslations } from 'helpers/utils';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { SettingsContext, SettingsContextProps } from 'contexts/SettingsContext';
import { useRollbar } from '@rollbar/react';
export interface IResponse {
  questionId: number;
  questionnaireId: number;
  response: number;
  responseOrder: number;
}

export interface IQuestionnaireData {
  questionnaire?: Questionnaire.IQuestionnaire;
  activities?: IActivities;
  textIntro?: ISectionBaseTexts;
}

export interface IUseAPIQuestionnaire {
  updateUserInformation: (birthYear: string, activity: string, country: string, civility: string) => Promise<boolean | undefined>;
  sendResponses: (responses: Array<IResponse>) => Promise<boolean>;
  loadQuestionnaireData: () => Promise<IQuestionnaireData>;
  loadAdditionalQuestionnaire: () => Promise<IQuestionnaireData>;
  sendAdditionalResponses: (responses: Array<IResponse>) => Promise<boolean>;
}

export const DEFAULT_QUESTIONNAIRE: Questionnaire.IQuestionnaire = {
  id: 0,
  total: 0,
  currentQuestionIndex: 0,
  questions: [],
  progression: 0,
};

function useAPIQuestionnaire(): IUseAPIQuestionnaire {
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { getRequest, showLoading, hideLoading } = useFetch<any>();
  const { t } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;
  const { languages } = useContext(SettingsContext) as SettingsContextProps;
  const rollbar = useRollbar();
  const getTextIntro = async (): Promise<ISectionBaseTexts | undefined> => {
    try {
      const options: AxiosRequestConfig = {
        url: API.ROUTES.QUESTIONNAIRE.INTRO,
        method: 'get',
      };

      const result: IHttpResponse = await getRequest(options);
      const texte = result.data.data?.texte;

      if (texte) {
        const translations = texte?.translations;

        return getSectionBaseTexts(translations, languages, '');
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }
    } catch (e: unknown) {
      console.log('Error while getting questionnaire intro texts : ', e);
      setToast({
        message: (e as string) || '',
        status: 'danger',
      });
    }

    return undefined;
  };

  function processCategories(categories: any): IActivities {
    let resultObj: any = {};

    Object.keys(categories).forEach((language) => {
      Object.keys(categories[language]).forEach((categorie: any) => {
        const currentCategory = categories[language][categorie];
        resultObj = {
          ...resultObj,
          [categorie]: {
            code: {
              ...resultObj[categorie]?.code,
              [language]: categorie,
            },
            label: {
              ...resultObj[categorie]?.label,
              [language]: currentCategory,
            },
          },
        };
      });
    });

    return Object.values(resultObj);
  }

  const getActivities = async (): Promise<IActivities | undefined> => {
    try {
      const options: AxiosRequestConfig = {
        url: API.ROUTES.QUESTIONNAIRE.ACTIVITIES,
        method: 'get',
      };

      const result: IHttpResponse = await getRequest(options);
      const categories = result.data.data?.categories;
      if (categories) {
        return processCategories(categories);
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }
    } catch (e: unknown) {
      console.log('Error while getting activities list : ', e);
      setToast({
        message: (e as string) || '',
        status: 'danger',
      });
    }

    return undefined;
  };

  const updateUserInformation = async (birthYear: string, activity: string, country: string, civility: string) => {
    showLoading();
    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.QUESTIONNAIRE.UPDATE_PROFILE}/${user?.id}`,
        method: 'patch',
        data: {
          userId: user?.id,
          year_birth: birthYear || 0,
          socio_category: activity,
          nationalite: country,
          civilite: civility
        },
      };

      const result: IHttpResponse = await getRequest(options);
      if (!result.data?.data?.user) {
        setToast({
          message: t('message.error.notupdateduserinfos'),
          status: 'danger',
        });

        return false;
      }
      setToast({
        message: t('message.updateduserinfos'),
        status: 'success',
      });

      return true;
    } catch (e: unknown) {
      console.log('Error while getting activities list : ', e);
      setToast({
        message: t('message.error.notupdateduserinfos'),
        status: 'danger',
      });
      return false;
    } finally {
      hideLoading();
    }
  };

  // Parse questions from questionnaire
  const parseQuestions = (
    questionsList: Array<any>,
    responses: Array<any> = [],
  ): Array<Questionnaire.IQuestion> => {
    return questionsList.map((item: any, key: number) => {
      // Look for the user's answer
      const userAnswer = responses.find(
        (ans: any) => ans.Reponse_Version_Question_Question === item.question_id,
      );
      return {
        id: item.question_id,
        label: getTranslations(languages, item.translations, (input) => input.libelle),
        order: item.order || key + 1,
        sectionId: item.group?.id || 0,
        sectionLabel: getTranslations(
          languages,
          item.group?.translations,
          (input) => input.libelle,
        ),
        responseMin: 1,
        responseMax: 7,
        responseMinLabel: getTranslations(
          languages,
          item.translations,
          (input) => input.libelle_note_min,
        ),
        responseMaxLabel: getTranslations(
          languages,
          item.translations,
          (input) => input.libelle_note_max,
        ),
        response: userAnswer?.Reponse_Version_Question_ReponseType || undefined,
      };
    });
  };

  // Load questionnaire
  const getQuestionnaire = async (userId: string, civility = 'Monsieur') => {
    try {
      const questionnaire: Questionnaire.IQuestionnaire = { ...DEFAULT_QUESTIONNAIRE };
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.QUESTIONNAIRE.QUESTIONS}/${civility}`,
        method: 'get',
      };

      const result: IHttpResponse = await getRequest(options);
      const questions = result.data.data?.questions;

      if (questions && Array.isArray(questions)) {
        questionnaire.id = result.data.data.questionnaire?.id;
        questionnaire.total = questions.length;

        // Get questions already answered
        const answersResult = await getRequest({
          url: `${API.ROUTES.QUESTIONNAIRE.USER_ANSWERS}/${userId}`,
          method: 'get',
        });
        const answers = answersResult.data.data?.reponses;
        questionnaire.questions = parseQuestions(questions, answers);

        const qAnswered = questionnaire.questions.filter((item) => !!item.response).length;
        questionnaire.progression = Math.floor((qAnswered / TOTAL_QUESTIONNAIRE) * 100);
        if (answersResult.data.data?.current) {
          questionnaire.currentQuestionIndex =
            questionnaire.questions.findIndex(
              (q) => q.order === answersResult.data.data?.current,
            ) || 0;
        }

        return questionnaire;
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }
    } catch (e: unknown) {
      setToast({
        message: 'Error loading questionnaire',
        status: 'danger',
      });
    }

    return undefined;
  };

  // Parse questions from questionnaire
  const parseAdditionalQuestions = (
    questionsList: Array<any>,
    responses: Array<any> = [],
  ): Array<Questionnaire.IQuestion> => {
    return questionsList.map((item: any, key: number) => {
      // Look for the user's answer
      const userAnswer = responses.find(
        (ans: any) => ans.question_id === item.question_id,
      );
      return {
        id: item.question_id,
        label: getTranslations(languages, item.translations, (input) => input.libelle),
        order: item.order || key + 1,
        sectionId: item.group?.id || 0,
        sectionLabel: getTranslations(
          languages,
          item.group?.translations,
          (input) => input.libelle,
        ),
        responseMin: 1,
        responseMax: 7,
        responseMinLabel: getTranslations(
          languages,
          item.translations,
          (input) => input.libelle_note_min,
        ),
        responseMaxLabel: getTranslations(
          languages,
          item.translations,
          (input) => input.libelle_note_max,
        ),
        response: userAnswer?.response || undefined,
        disableButShowPreviousButton: key === 0
      };
    });
  };

  // load additional questionnaire
  const getAdditionalQuestionnaire = async (userId: string) => {
    try {
      const questionnaire: Questionnaire.IQuestionnaire = { ...DEFAULT_QUESTIONNAIRE };
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.QUESTIONNAIRE.ADDITIONAL.GET}/${userId}`,
        method: 'get',
      };

      const result: IHttpResponse = await getRequest(options);
      const questions = result.data.data?.questions;
      if (questions && Array.isArray(questions)) {
        const answersResult = await getRequest({
          url: `${API.ROUTES.QUESTIONNAIRE.ADDITIONAL.ANSWERS_LIST}/${userId}`,
          method: 'get',
        });
        const answers = answersResult.data.data?.reponses;
        questionnaire.questions = parseAdditionalQuestions(questions, answers);
        const qAnswered = questionnaire.questions.filter((item) => !!item.response).length;

        questionnaire.progression = Math.floor(((qAnswered / TOTAL_QUESTIONNAIRE) * 100) + (PRIMARY_QUESTIONNAIRE_NB * 100 / TOTAL_QUESTIONNAIRE));
        if (answersResult.data.data?.current) {
          questionnaire.currentQuestionIndex =
            questionnaire.questions.findIndex(
              (q) => q.order === answersResult.data.data?.current,
            ) || 0;
        }
        return questionnaire;
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }
    } catch (e: unknown) {
      setToast({
        message: 'Error loading questionnaire',
        status: 'danger',
      });
    }

    return undefined;
  };

  const sendResponses = async (responses: Array<IResponse>) => {
    showLoading();

    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.QUESTIONNAIRE.ANSWER}/${user?.id}`,
        method: 'post',
        data: {
          answers: responses.map((rep) => ({
            questionsId: rep.questionId,
            response_type: rep.response,
            order: rep.responseOrder,
            questionnaireId: rep.questionnaireId,
          })),
        },
      };

      const result: IHttpResponse = await getRequest(options);
      const repState = result.data.data?.reponses;

      if (result.status !== 200 || !repState || repState?.response === null) {
        setToast({
          message: 'Error sending response to server',
          status: 'danger',
        });
        rollbar.error('Error sending response to server',
          {
            status: result.status,
            repstate: repState,
            responses: responses,
            message: 'result.status !== 200 || !repState || repState?.response === null',
            user: user
          });
        return false;
      }

      rollbar.info(`Sent questionnaire responses by user ${user?.id} ${user?.email} on question: ${responses[0].questionId}`, {
        responses, user: {
          id: user?.id,
          email: user?.email,
        },
        repstate: repState,
      });
      return !!repState;
    } catch (e: unknown) {
      setToast({
        message: (e as string) || '',
        status: 'danger',
      });
      rollbar.error(`Error while sending questionnaire response for user: ${user?.id} ${user?.email} on question: ${responses[0].questionId}`, {
        error: e,
        user: {
          id: user?.id,
          email: user?.email,
        },
        responses,
      });
    } finally {
      hideLoading();
    }

    return false;
  };

  const loadQuestionnaireData = async () => {
    const qData: IQuestionnaireData = {};
    showLoading();

    try {
      qData.questionnaire = await getQuestionnaire(`${user?.id}`, `${user?.civility}`);
      if (qData.questionnaire?.id) {
        // Check if not yet done, else do not load other data
        if (qData.questionnaire.progression < (PRIMARY_QUESTIONNAIRE_NB * 100 / TOTAL_QUESTIONNAIRE)) {
          // Load text intro
          qData.textIntro = await getTextIntro();
          // If user NOT already answered age & activity
          qData.activities = await getActivities();
        }
      }
    } catch (e: unknown) {
      setToast({
        message: (e as string) || 'Error while loading questionnaire',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return qData;
  };

  const sendAdditionalResponses = async (responses: Array<IResponse>) => {
    showLoading();
    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.QUESTIONNAIRE.ADDITIONAL.ANSWER}/${user?.id}`,
        method: 'post',
        data: {
          answers: responses.map((rep) => ({
            questionsId: rep.questionId,
            response_type: rep.response,
            order: rep.responseOrder,
            questionnaireId: rep.questionnaireId,
          })),
        },
      };

      const result: IHttpResponse = await getRequest(options);
      const repState = result.data.data?.reponses;

      if (result.status !== 200 || !repState || repState?.response === null) {
        setToast({
          message: 'Error sending response to server',
          status: 'danger',
        });
        rollbar.error('Error sending response to server', {
          status:
            result.status,
          repstate: repState,
          response: repState?.response,
          message: 'result.status !== 200 || !repState || repState?.response === null',
          user: user
        });
        return false;
      }

      rollbar.info(`Sent additional questionnaire responses by user ${user?.id} ${user?.email} on question: ${responses[0].questionId}`, {
        responses,
        user: {
          id: user?.id,
          email: user?.email,
        }
      });
      return !!repState;
    } catch (e: unknown) {
      setToast({
        message: (e as string) || '',
        status: 'danger',
      });
      rollbar.error(`Error while sending additional questionnaire response for user: ${user?.id} ${user?.email} on question: ${responses[0].questionId}`,
        {
          error: e,
          user: {
            id: user?.id,
            email: user?.email,
          },
          responses,
        });
    } finally {
      hideLoading();
    }

    return false;
  };

  const loadAdditionalQuestionnaire = async () => {
    const qData: IQuestionnaireData = {};
    showLoading();

    try {
      qData.questionnaire = await getAdditionalQuestionnaire(`${user?.id}`);
    } catch (e: unknown) {
      rollbar.error(`Error while loading additional questionnaire for user: ${user?.id} ${user?.email}`, { error: e, user: user });
    } finally {
      hideLoading();
    }

    return qData;
  };

  return {
    updateUserInformation,
    loadQuestionnaireData,
    sendResponses,
    sendAdditionalResponses,
    loadAdditionalQuestionnaire
  };
}

export default useAPIQuestionnaire;
