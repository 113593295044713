import React, { useEffect, useRef, useState } from 'react';
import { CustomInput, CustomSelectWrapper, DropdownItem, DropdownList } from 'components/common/form/custom-select/CustomSelect.styled';
import IconChevron from 'components/common/icons/icon-chevron/IconChevron';
import { Form } from 'types/app';

export interface CustomSelectProps {
    options: Array<Form.IOption>;
    onChange?: (e: Form.IOption | null) => void;
    label?: string;
    required?: boolean;
    showError?: boolean | null;
    errorMessage?: string;
    defaultValue?: string;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ options, onChange, label, required, defaultValue, errorMessage }) => {
    const [inputValue, setInputValue] = useState<string>(options.find((option) => option.value?.toLowerCase() === defaultValue?.toLowerCase())?.label || '');
    const [selectedOption, setSelectedOption] = useState<Form.IOption | null>(options.find((option) => option.value?.toLowerCase()  === defaultValue?.toLowerCase() ) || null);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedValue = e.target.value;
        setInputValue(selectedValue);
        setShowDropdown(true);
        setHighlightedIndex(-1);
        setSelectedOption(null);
        onChange && onChange(null);
    };

    const handleOptionSelect = (option: Form.IOption) => {
        setSelectedOption(option);
        setInputValue(option.label);
        setShowDropdown(false);
        onChange && onChange(option);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                setShowDropdown(false);
            }
        };

        const handleEscapePress = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscapePress);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscapePress);
        };
    }, []);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'ArrowDown') {
            setHighlightedIndex((prevIndex) =>
                prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : prevIndex
            );
        } else if (e.key === 'ArrowUp') {
            setHighlightedIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : prevIndex
            );
        } else if (e.key === 'Enter') {
            if (highlightedIndex >= 0) {
                handleOptionSelect(filteredOptions[highlightedIndex]);
            }
        }
    };

    return (
        <CustomSelectWrapper ref={wrapperRef}>
            <div className="custom-input-container">
                <CustomInput
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onClick={() => setShowDropdown(!showDropdown)}
                />
                {label &&
                    <div className={`placeholder ${showDropdown || !!selectedOption?.value || !!inputValue ? 'active' : ''}`}>
                        {label} {required && <span className="required">*</span>}
                    </div>
                }
            </div>
            <div onClick={() => setShowDropdown(!showDropdown)} className='chevron-wrapper'>
                <IconChevron mode="downup" opened={!!showDropdown} />
            </div>
            {showDropdown && filteredOptions.length > 0 && (
                <DropdownList>
                    {filteredOptions.map((option, index) => (
                        <DropdownItem
                            key={index}
                            isSelected={option.value === selectedOption?.value}
                            isHighlighted={index === highlightedIndex}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => handleOptionSelect(option)}
                        >
                            {option.label}
                        </DropdownItem>
                    ))}
                </DropdownList>
            )}
            {errorMessage && <div className='error'>{errorMessage}</div>}
        </CustomSelectWrapper>
    );
};

export default CustomSelect;